import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNumericFormat } from "react-number-format";
import { SavingsAccount } from "Services/api/profile/interfaces";
import { BankAccountFields, BankAccountFieldsSchema } from "Shared/BankAccountFields/BankAccountFields";
import FormikForm from "Shared/FormikForm/FormikForm";
import { FormikMoney, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { SavingsAccountSelect } from "Shared/SavingAccountSelect/SavingAccountSelect";
import { SectionTitle } from "Shared/styled";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export function TransferRequest() {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<SavingsAccount>();
  const accountField = "to";
  const bankAccountSchema = BankAccountFieldsSchema(accountField);
  const balance = account?.availableBalance || 0;

  return (
    <Stack>
      <SectionTitle variant="h1">Solicitud de transferencia</SectionTitle>
      <Formik
        initialValues={{
          from: "",
          [accountField]: "",
          accountNumber: "",
          accountBank: "",
          accountType: "",
          accountId: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await Promise.resolve(
              console.log({
                ...values,
                from: account?.number,
              })
            );
          } catch (error) {
            enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
          }

          setSubmitting(false);
        }}
        validationSchema={Yup.object({
          from: requiredNumberValidation,
          [accountField]: requiredValidation,
          amount: requiredNumberValidation
            .positive("Debe ser positivo")
            .max(balance, `No puede ser mayor que RD$${format(balance.toString())}`),
        }).concat(bankAccountSchema)}
      >
        <FormikForm width="100%">
          <SavingsAccountSelect
            name="from"
            label="Cuenta a debitar"
            exclude={["contributionCertificate"]}
            onChange={(value) => setAccount(value)}
          />
          <BankAccountFields accountField={accountField} label="Cuenta a transferir" />

          <Amount balance={balance} />
          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </FormikForm>
      </Formik>
    </Stack>
  );
}

function Amount({ balance }: { balance: number }) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  return (
    <FormikMoney
      name="amount"
      id="amount"
      fullWidth
      variant="outlined"
      label="Monto a transferir"
      helperText={`Balance disponible RD$${format(balance.toString())}`}
    />
  );
}
