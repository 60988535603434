import { InputAdornment, MenuItem, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { CertificateLimitsResponse } from "Services/api/request/interfaces";
import { getLiquidationChoices } from "Services/api/request/request";
import { BankAccountFields, BankAccountFieldsSchema } from "Shared/BankAccountFields/BankAccountFields";
import { FormikMoney, FormikMoneyField, numericConfig } from "Shared/FormikMoney/FormikMoney";
import FormikSelect, { FormikSelectProps } from "Shared/FormikSelect/FormikSelect";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { Query } from "Shared/Query/Query";
import { SavingsAccountSelect } from "Shared/SavingAccountSelect/SavingAccountSelect";
import { SourceOfFunds, useSourceOfFundsSchema } from "Shared/SourceOfFunds/SourceOfFunds";
import { TypeOfPayment } from "Shared/TypeOfPayment/TypeOfPayment";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export function CertificateForm({
  options,
  isRegistration = false,
  institutionIsAffiliated,
  dbSalary,
}: {
  options: CertificateLimitsResponse["data"];
  isRegistration?: boolean;
  institutionIsAffiliated: boolean;
  dbSalary: FormikMoneyField;
}) {
  const { minTimeLimit, maxTimeLimit } = options;
  const [minTerm, maxTerm] = [minTimeLimit, maxTimeLimit];

  return (
    <Fragment>
      <FormikMoney name="amount" id="amount" label="Monto" helperText="Monto minimo: RD$5,000" required />
      <SourceOfFunds
        name="amount"
        institutionIsAffiliated={institutionIsAffiliated}
        dbSalary={dbSalary}
        amountRequired
      />
      <FormikSlider
        id="term"
        name="term"
        label="Plazo"
        aria-label="Plazo"
        valueLabelDisplay="auto"
        min={minTerm}
        max={maxTerm}
        step={1}
        marks
        formatValue={(value) => `${value} meses`}
      />

      <Interest options={options} />
      <LiquidationSelect isRegistration={isRegistration} />
      <SavingsAccount isRegistration={isRegistration} />
      <TransferAccount isRegistration={isRegistration} />
      <TypeOfPayment />
    </Fragment>
  );
}

function SavingsAccount({ isRegistration }: { isRegistration: boolean }) {
  const [{ value: liquidation }] = useField<string>("liquidation");

  if (isRegistration || liquidation !== "withdrawable") return null;

  return <SavingsAccountSelect name="savingsAccount" label="Cuenta a depositar" include={["in_sight"]} />;
}

function TransferAccount({ isRegistration }: { isRegistration: boolean }) {
  const [{ value: liquidation }] = useField<string>("liquidation");

  if (liquidation !== "liquidable") return null;

  return <BankAccountFields accountField="account" showAccountField={!isRegistration} label="Cuenta a transferir" />;
}

function LiquidationSelect({ isRegistration }: { isRegistration: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const [{ value: account }, , { setValue: setAccount }] = useField<string>("account");
  const [, , { setValue: setLiquidation }] = useField<string>("liquidation");

  const result = useQuery({
    queryKey: [getLiquidationChoices.name],
    queryFn: async () => {
      try {
        const response = await getLiquidationChoices();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const props: FormikSelectProps<string> = {
    id: "liquidation",
    name: "liquidation",
    label: "Forma de liquidación",
    SelectDisplayProps: { "aria-label": "Forma de liquidación" },
    required: true,
    onChange: (ev) => {
      const value = ev.target.value;
      setLiquidation(value);
      if (value === "liquidable" && isRegistration) setAccount("new");
      else if (value !== "liquidable" && account) setAccount("");
    },
  };

  return (
    <Query
      result={result}
      OnLoading={() => (
        <Stack spacing={1} width="100%">
          <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
          <Skeleton variant="rectangular" width={"100%"} height={56} />
        </Stack>
      )}
      onError={() => <FormikSelect {...props} />}
      onSuccess={(liquidation) => (
        <FormikSelect {...props}>
          {liquidation.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      )}
    />
  );
}

function Interest({ options: { description: rates } }: { options: CertificateLimitsResponse["data"] }) {
  const [{ value: amount }] = useField<FormikMoneyField>("amount");
  const [{ value: term }] = useField<number>("term");
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig, fixedDecimalScale: true });
  let interestRate = 0;
  let interest = 0;

  function calculateInterest(amount: number, interestRate: number) {
    const monthlyInterest = (interestRate / 100 / 360) * 30;
    return amount * monthlyInterest * 0.9;
  }

  if (amount)
    for (const { min, max, minTimeLimit, maxTimeLimit, capitalizedInterestRate } of rates) {
      if (capitalizedInterestRate > interestRate) interestRate = capitalizedInterestRate;
      if (amount >= min && amount <= max && term >= minTimeLimit && term <= maxTimeLimit) {
        interestRate = capitalizedInterestRate;
        interest = calculateInterest(amount, interestRate);
        break;
      }
      interest = calculateInterest(amount, interestRate);
    }

  return (
    <Fragment>
      <FormikTextField
        id="rate"
        name="rate"
        label="Tasa anual"
        type="number"
        value={interestRate.toString()}
        variant="outlined"
        disabled
      />
      <NumericFormat
        name="interest"
        id="interest"
        fullWidth
        label="Interes mensual a percibir"
        variant="outlined"
        value={format(interest.toString())}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        customInput={FormikTextField}
        disabled
        {...numericConfig}
      />
    </Fragment>
  );
}

export function CertificateFormSkeleton() {
  return (
    <Stack width={"100%"} spacing={2}>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Skeleton variant="rectangular" width={"100%"} height={37} />
    </Stack>
  );
}

export function useCertificateFormSchema({
  options: { minTimeLimit, maxTimeLimit, minimumBalance },
  institutionIsAffiliated,
  dbSalary,
}: {
  options: CertificateLimitsResponse["data"];
  institutionIsAffiliated: boolean;
  dbSalary: FormikMoneyField;
}) {
  const sourceOfFundsSchema = useSourceOfFundsSchema("amount", institutionIsAffiliated, dbSalary, true);
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig, fixedDecimalScale: true });
  const [minTerm, maxTerm] = [minTimeLimit, maxTimeLimit];
  const bankAccountSchema = BankAccountFieldsSchema("account");

  return Yup.object({
    amount: requiredNumberValidation
      .nullable()
      .min(minimumBalance, `Monto tiene que ser mayor que ${format(minimumBalance.toString())}`),
    term: Yup.number()
      .required("Requerido")
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .min(minTerm, `No menos de ${minTerm} meses`)
      .max(maxTerm, `No mas de ${maxTerm} meses`),
    liquidation: requiredValidation,
    typeofPayment: requiredValidation,
    account: Yup.string().when("typeofPayment", {
      is: "liquidable",
      then: () => requiredValidation,
    }),
  })
    .concat(bankAccountSchema)
    .concat(sourceOfFundsSchema);
}
