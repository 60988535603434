import { Skeleton, Stack } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SourceOfFundsDataResponse } from "Services/api/profile/interfaces";
import { getSourceOfFundsData } from "Services/api/profile/profile";
import { SavingsAccountPayload, SavingsDepositLimitsResponse } from "Services/api/request/interfaces";
import { getProductRequests, getSavingsAccountDepositLimits, saveAccountRequest } from "Services/api/request/request";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import { Query } from "Shared/Query/Query";
import {
  SavingsAccountForm,
  SavingsAccountType,
  useSavingsAccountFormSchema,
} from "Shared/SavingsAccountForm/SavingsAccountForm";
import { SavingsTypeSelect } from "Shared/SavingsTypeSelect/SavingsTypeSelect";
import { SectionTitle } from "Shared/styled";

export function SavingsAccountRequest() {
  const { enqueueSnackbar } = useSnackbar();

  const optionsResult = useQuery({
    queryKey: [getSavingsAccountDepositLimits.name],
    queryFn: async () => {
      try {
        const response = await getSavingsAccountDepositLimits();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  const userDataResult = useQuery({
    queryKey: [getSourceOfFundsData.name],
    queryFn: async () => {
      try {
        const response = await getSourceOfFundsData();
        return response.data;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
  });

  return (
    <Stack>
      <SectionTitle variant="h1">Solicitud de cuenta de ahorros</SectionTitle>
      <Query
        result={optionsResult}
        OnLoading={() => <SavingsSkeleton />}
        onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
        onSuccess={(optionsResult) => (
          <Query
            result={userDataResult}
            OnLoading={() => <SavingsSkeleton />}
            onError={() => <>Ha ocurrido un error al intentar obtener las opciones del formulario</>}
            onSuccess={(userDataResult) => <Form result={optionsResult} userDataResult={userDataResult} />}
          />
        )}
      />
    </Stack>
  );
}

interface FormProps {
  result: SavingsDepositLimitsResponse["data"];
  userDataResult: SourceOfFundsDataResponse["data"];
}

function Form({ result, userDataResult }: FormProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { institutionIsAffiliated, salary: dbSalary } = userDataResult;
  const { minMonthlyDeposit } = result;
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useSavingsAccountFormSchema({
    ...result,
    institutionIsAffiliated,
    dbSalary,
  });

  const initialValues = {
    type: "",
    childName: "",
    childAge: "",
    birthCertificate: undefined,
    initialDeposit: null,
    salary: null,
    sourceOfFundsDesc: "",
    sourceOfFundsFile: undefined,
    monthlyDeposit: minMonthlyDeposit,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const requestData = new FormData();

          for (const key in values) {
            const el = values[key as keyof SavingsAccountPayload];
            if (el) requestData.append(key, typeof el === "number" ? el.toString() : el);
          }
          const { data } = await saveAccountRequest(requestData);
          enqueueSnackbar("Solicitud creada exitosamente", { variant: "success" });

          void queryClient.invalidateQueries({
            queryKey: [getProductRequests.name],
            exact: true,
          });

          if (data.signatureRequired)
            navigate({
              pathname: "/solicitudes/firma/",
              search: createSearchParams({
                id: data.uniqueId,
                type: data.type,
              }).toString(),
            });
          else navigate("/solicitudes/estados/");

          setSubmitting(false);
        } catch (error) {
          enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        }

        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      <FormikForm width="100%">
        <SavingsTypeSelect name="type" label="Tipo de cuenta" removePlan />
        <SavingsAccount result={result} userDataResult={userDataResult} />

        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}

function SavingsAccount({
  result: { minMonthlyDeposit, maxMonthlyDeposit },
  userDataResult: { institutionIsAffiliated, salary },
}: FormProps) {
  const [{ value }] = useField<SavingsAccountType>("type");

  return (
    <SavingsAccountForm
      minMonthlyDeposit={minMonthlyDeposit}
      maxMonthlyDeposit={maxMonthlyDeposit}
      type={value}
      institutionIsAffiliated={institutionIsAffiliated}
      dbSalary={salary}
    />
  );
}

function SavingsSkeleton() {
  return (
    <Stack width={"100%"} spacing={2}>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Stack spacing={1} width="100%">
        <Skeleton variant="text" sx={{ fontSize: "14px" }} width={120} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
        <Skeleton variant="rectangular" width={"100%"} height={56} />
      </Stack>
      <Skeleton variant="rectangular" width={"100%"} height={37} />
    </Stack>
  );
}
